import React from 'react';
import PropTypes from 'prop-types';

const InfoWindow = (props) => {
  const { marker } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: '8rem',
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
    borderRadius: '5px',
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {marker.title}
      </div>

      <div style={{ fontSize: 14, color: 'grey', marginTop: '0.5rem'}}>
        {marker.openings}
      </div>

      <div style={{ fontSize: 14, color: 'grey', marginTop: '1rem'}}>
        {marker.address}
      </div>
    </div>
  );
};

// Marker component
const Marker = ({ image, size, marker, show }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    minHeight: 10,
    minWidth: 10,
    backgroundColor: show ? '#23325b' : '#d73f1c',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div className="map-marker" style={markerStyle} />
      {show && <InfoWindow marker={marker} />}
    </>
  );
};

export default Marker;
