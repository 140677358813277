import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';
import Marker from './Marker.js';

class CustomMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
    };
  }

	componentDidMount() {
		this.setState({ places: (this.props && this.props.branches) ? this.props.branches : [] });
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    this.setState((state) => {
      const index = parseInt(key.replace('marker-', ''));

			// console.log('onChildClickCallback', key, index);

			for (let i = 0; i < state.places.length; i++) {
				if (i === index) {
					state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
				} else {
					state.places[i].show = false;
				}
			}

      return { places: state.places };
    });
  };

  render() {
    const { places } = this.state;

		const Markers = places && places.map((marker, index) => (
			(marker && marker.title && marker.title.length > 0) ?
				<Marker
					key={(marker.id) ? marker.id : ('marker-' + index)}
					lat={marker.pinLat}
					lng={marker.pinLng}

					image={marker.pin && marker.pin.src ? marker.pin.src : null}
					size={{
						width: marker.pinWidth,
						height: marker.pinHeight,
					}}
					show={marker.show}
					marker={marker} /> : null
		));

    return (
			<GoogleMapReact
				{...this.props}
				onChildClick={this.onChildClickCallback}
				>
				{Markers}
			</GoogleMapReact>
    );
  }
}

const CustomBranchesMap = (props) => {
	const { element } = props;
	const { data: { map, branches } } = element;
	const center = {
		lat: map && parseFloat(map.lat) ? parseFloat(map.lat) : 0,
		lng: map && parseFloat(map.lng) ? parseFloat(map.lng) : 0
	}

	const mapOptions = (maps) => {
		return {
			panControl: false,
			mapTypeControl: false,
			styles: [
			  {
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#f5f5f5"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.icon",
			    "stylers": [
			      {
			        "visibility": "off"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#616161"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.text.stroke",
			    "stylers": [
			      {
			        "color": "#f5f5f5"
			      }
			    ]
			  },
			  {
			    "featureType": "administrative.land_parcel",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#bdbdbd"
			      }
			    ]
			  },
			  {
			    "featureType": "poi",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#eeeeee"
			      }
			    ]
			  },
			  {
			    "featureType": "poi",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#757575"
			      }
			    ]
			  },
			  {
			    "featureType": "poi.park",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#e5e5e5"
			      }
			    ]
			  },
			  {
			    "featureType": "poi.park",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  },
			  {
			    "featureType": "road",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#ffffff"
			      }
			    ]
			  },
			  {
			    "featureType": "road.arterial",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#757575"
			      }
			    ]
			  },
			  {
			    "featureType": "road.highway",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#dadada"
			      }
			    ]
			  },
			  {
			    "featureType": "road.highway",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#616161"
			      }
			    ]
			  },
			  {
			    "featureType": "road.local",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.line",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#e5e5e5"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.station",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#eeeeee"
			      }
			    ]
			  },
			  {
			    "featureType": "water",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#c9c9c9"
			      }
			    ]
			  },
			  {
			    "featureType": "water",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  }
			]
		}
	}

	// console.log(map, branches, center);

	return (
		<div className="map-box">
			<div className="map-box__inner">
				<CustomMap
					bootstrapURLKeys={{ key: map && map.key ? map.key : null }}
					defaultCenter={center}
					defaultZoom={map && parseInt(map.zoom) ? parseInt(map.zoom) : 1}
					options={mapOptions}
					branches={branches}
				>
				</CustomMap>
			</div>
		</div>
	)
};

export default CustomBranchesMap;
