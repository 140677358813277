import React from "react";
import { Select } from "@webiny/ui/Select";
import { Input } from '@webiny/ui/Input';
import { Form } from '@webiny/form';

const fees = {
	cee: {
		czk: [
			{threshold: 1000, fee: 70},
			{threshold: 2000, fee: 100},
			{threshold: 3000, fee: 110},
			{threshold: 4000, fee: 120},
			{threshold: 5000, fee: 130},
			{threshold: 10000, fee: 270},
			{threshold: 15000, fee: 380},
			{threshold: 20000, fee: 440},
			{threshold: 25000, fee: 520},
			{threshold: 30000, fee: 630},
			{threshold: 50000, fee: 2.5, percent: true},
			{threshold: 60000, fee: 2.5, percent: true},
			{threshold: 80000, fee: 2.5, percent: true},
			{threshold: 100000, fee: 2.5, percent: true},
			{threshold: 120000, fee: 2.5, percent: true},
		],
		eur: [
			{threshold: 100, fee: 5},
			{threshold: 200, fee: 7},
			{threshold: 300, fee: 10},
			{threshold: 400, fee: 15},
			{threshold: 500, fee: 18},
			{threshold: 1000, fee: 25},
			{threshold: 2000, fee: 2.5, percent: true},
			{threshold: 2500, fee: 2.5, percent: true},
			{threshold: 5000, fee: 2.5, percent: true},
		],
		usd: [
			{threshold: 100, fee: 5},
			{threshold: 200, fee: 7},
			{threshold: 300, fee: 10},
			{threshold: 400, fee: 15},
			{threshold: 500, fee: 18},
			{threshold: 1000, fee: 25},
			{threshold: 2000, fee: 2.5, percent: true},
			{threshold: 2500, fee: 2.5, percent: true},
			{threshold: 5000, fee: 2.5, percent: true},
		],
	},
	cis: {
		czk: [
			{threshold: 1000000, fee: 2.5, percent: true, min: 70},
		],
		eur: [
			{threshold: 1000000, fee: 2.5, percent: true, min: 3},
		],
		usd: [
			{threshold: 1000000, fee: 2.5, percent: true, min: 3},
		],
	},
	vn: {
		czk: [
			{threshold: 1000000, fee: 1.6, percent: true, min: 100},
		],
		eur: [
			{threshold: 1000000, fee: 1.6, percent: true, min: 5},
		],
		usd: [
			{threshold: 1000000, fee: 1.6, percent: true, min: 5},
		],
	},
	we: {
		czk: [
			{threshold: 2000, fee: 80},
			{threshold: 3000, fee: 120},
			{threshold: 4000, fee: 200},
			{threshold: 5000, fee: 250},
			{threshold: 10000, fee: 390},
			{threshold: 15000, fee: 550},
			{threshold: 20000, fee: 650},
			{threshold: 25000, fee: 750},
			{threshold: 30000, fee: 850},
			{threshold: 50000, fee: 3.3, percent: true},
			{threshold: 60000, fee: 3.3, percent: true},
			{threshold: 80000, fee: 3.3, percent: true},
			{threshold: 100000, fee: 3.3, percent: true},
		],
		eur: [
			{threshold: 100, fee: 5},
			{threshold: 200, fee: 10},
			{threshold: 300, fee: 13},
			{threshold: 400, fee: 17},
			{threshold: 500, fee: 20},
			{threshold: 750, fee: 27},
			{threshold: 1000, fee: 33},
			{threshold: 2000, fee: 3.3, percent: true},
			{threshold: 2500, fee: 3.3, percent: true},
			{threshold: 5000, fee: 3.3, percent: true},
		],
		usd: [
			{threshold: 100, fee: 5},
			{threshold: 200, fee: 10},
			{threshold: 300, fee: 13},
			{threshold: 400, fee: 17},
			{threshold: 500, fee: 20},
			{threshold: 750, fee: 27},
			{threshold: 1000, fee: 33},
			{threshold: 2000, fee: 3.3, percent: true},
			{threshold: 2500, fee: 3.3, percent: true},
			{threshold: 5000, fee: 3.3, percent: true},
		],
	},
	others: {
		czk: [
			{threshold: 1000, fee: 90},
			{threshold: 2000, fee: 120},
			{threshold: 3000, fee: 200},
			{threshold: 4000, fee: 250},
			{threshold: 5000, fee: 290},
			{threshold: 10000, fee: 430},
			{threshold: 15000, fee: 590},
			{threshold: 20000, fee: 790},
			{threshold: 25000, fee: 840},
			{threshold: 30000, fee: 880},
			{threshold: 50000, fee: 3, percent: true},
			{threshold: 60000, fee: 3, percent: true},
			{threshold: 80000, fee: 3, percent: true},
			{threshold: 100000, fee: 3, percent: true},
			{threshold: 120000, fee: 3, percent: true},
		],
		eur: [
			{threshold: 100, fee: 7},
			{threshold: 150, fee: 9},
			{threshold: 200, fee: 12},
			{threshold: 300, fee: 15},
			{threshold: 500, fee: 25},
			{threshold: 750, fee: 30},
			{threshold: 1000, fee: 35},
			{threshold: 1300, fee: 3, percent: true},
			{threshold: 2000, fee: 3, percent: true},
			{threshold: 2500, fee: 3, percent: true},
			{threshold: 3500, fee: 3, percent: true},
			{threshold: 5000, fee: 3, percent: true},
		],
		usd: [
			{threshold: 100, fee: 7},
			{threshold: 150, fee: 9},
			{threshold: 200, fee: 12},
			{threshold: 300, fee: 15},
			{threshold: 500, fee: 25},
			{threshold: 750, fee: 30},
			{threshold: 1000, fee: 35},
			{threshold: 1300, fee: 3, percent: true},
			{threshold: 2000, fee: 3, percent: true},
			{threshold: 2500, fee: 3, percent: true},
			{threshold: 3500, fee: 3, percent: true},
			{threshold: 5000, fee: 3, percent: true},
		],
	}
};
const states = {
  cee: ['Albánie', 'Bulharsko', 'Černá Hora', 'Estonsko', 'Chorvatsko', 'Kosovo', 'Litva', 'Lotyšsko', 'Nová Makedonie', 'Polsko', 'Rumunsko', 'Srbsko'],
  cis: ['Arménie', 'Bělorusko', 'Gruzie', 'Moldávie', 'Rusko', 'Ukrajina', 'Kazachstán', 'Uzbekistán', 'Kyrgyzstán', 'Tádžikistán', 'Turecko'],
  vn: ['Vietnam'],
  we: ['Belgie', 'Dánsko', 'Finsko', 'Francie', 'Irsko', 'Itálie', 'Lucembursko', 'Německo', 'Nizozemsko', 'Norsko', 'Portugalsko', 'Rakousko', 'Španělsko', 'Švédsko', 'Švýcarsko', 'Velká Británie'],
  others: [''],
};

const Calc = ({ element }) => {
	// const { data: { calc } } = element;

	const countries = [
		{value: 'cee', label: 'Centrální Evropa'},
		{value: 'cis', label: 'Východní Evropa a střední Asie'},
		{value: 'vn', label: 'Vietnam'},
		{value: 'we', label: 'Západní Evropa'},
		{value: 'others', label: 'Zbytek světa'},
	];
	const currencies = [
		{value: 'czk', label: 'CZK'},
		{value: 'eur', label: 'EUR'},
		{value: 'usd', label: 'USD'},
	];
	const settings = {
		currency: currencies[0].value,
		country: countries[0].value,
		amount: null,
		result: 'empty'
	};

	const format = (amount, item) => {
		if (item.percent) {
			const result = Math.round(amount * (item.fee / 100));

			if (item.min && item.min > result) {
				return item.min;
			} else {
				return result;
			}

		} else {
			return item.fee;
		}
	}

	const calculate = (data) => {
		const amount = parseInt(data.amount);

		if (fees[data.country] && fees[data.country][data.currency]) {
			for (let i = 0; i < fees[data.country][data.currency].length; i++) {
				if ((i === 0 && amount <= fees[data.country][data.currency][i].threshold) || (i > 0 && amount > fees[data.country][data.currency][i - 1].threshold && amount <= fees[data.country][data.currency][i].threshold)) {
					return format(amount, fees[data.country][data.currency][i]);
				}
			}
		}

		return '-';
	}

	return (
		<Form
      data={settings}
      onSubmit={async data => {
          console.log(`Form submit`, data);
					calculate(data);
      }}
    >
		{({ Bind, form, data }) => {

			return (
			<div className="finwise-calc">
				<div className="finwise-calc__inner">
					<div className="webiny-pb-layout-row">
						<div className="webiny-pb-layout-column">
							<Bind name={'country'}>
							<Select
							label="Země"
							disabled={false}
							box="true"
							// description="Vyberte zemi."
							>
							{countries.map(t => {
								return (
									<option key={t.value} value={t.value}>
									{t.label}
									</option>
								);
							})}
							</Select>
							</Bind>
						</div>
						<div className="webiny-pb-layout-column">
							<Bind name={'amount'}>
							<Input label={'Částka'}
								className="input-amount"
 								disabled={false} />
							</Bind>
						</div>
						<div className="webiny-pb-layout-column">
							<Bind name={'currency'}>
							<Select
							className="input-currency"
							label="Měna"
							disabled={false}
							box="true"
							>
							{currencies.map(t => {
								return (
									<option key={t.value} value={t.value}>
									{t.label}
									</option>
								);
							})}
							</Select>
							</Bind>
						</div>
					</div>
          <div className="webiny-pb-layout-row country-list">
          { data.country ?
            states[data.country].join(', ') : null
          }
          </div>
					<div className="webiny-pb-layout-row">
						{ data.amount ?
							<>
								<div className="total">
									<h3>Celkem</h3>
									<span className="total--amount"><strong>{(calculate(data) !== '-') ? parseInt(data.amount) + calculate(data) : '-'}</strong></span>
									<span className="total--currency">{data.currency}</span>
								</div>
								<div className="fee">
									<h3>Poplatek</h3>
									<span className="fee--amount"><strong>{calculate(data)}</strong></span>
									<span className="fee--currency">{data.currency}</span>
								</div>
							</> : null
						}
					</div>
				</div>
			</div>
		)}}
		</Form>
	)
};

export default Calc;
